<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 专家入驻 -->
        <div class="settleinbox">
            <div class="settleincon">
                <div class="title">专家认证</div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="300px" class="demo-ruleForm">
                    <el-form-item label="真实姓名" prop="username">
                        <el-input v-model="ruleForm.username" placeholder="请输入真实姓名" maxlength="8" style="width: 50%;"></el-input>
                    </el-form-item>
                    <el-form-item label="头像" prop="userhead" class="head">
                        <el-upload
                            class="avatar-uploader"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="ruleForm.userhead" :src="ruleForm.userhead" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <span class="mark">尺寸不小于100x100，不超过2MB，格式jpg/png</span>
                    </el-form-item>
                    <el-form-item label="擅长领域" prop="fieldvalue">
                        <el-checkbox @change="changecheckbox(item)" :label="item.text" v-model="item.checked" v-for="(item,index) in fieldlist" :key="index"></el-checkbox>
                    </el-form-item>
                    <el-form-item label="头衔" prop="tipsdata">
                        <div class="tipsbox">
                            <div class="tipshide" v-if="ruleForm.tipsdata==''"><i>例：</i><span>高考志愿填报专家</span><span>高考升学规划专家</span><span>生涯规划专家</span></div>
                            <div class="tipsshow" v-else>
                                <span v-for="(item,index) in ruleForm.tipsdata" :key="index">{{item}}<i class="iconfont" @click.prevent="tipsremove(item)">&#xe620;</i></span>
                            </div>
                        </div>
                        <div class="tipsform">
                            <input v-model="tipstxt" placeholder="请输入头衔" maxlength="20"/>
                            <div class="btn" @click.prevent="tipsadd()">添加</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="简介" prop="usermark">
                        <el-input v-model="ruleForm.usermark" type="textarea" :autosize="{ minRows: 6, maxRows: 6}" placeholder="请输简介" maxlength="1000"></el-input>
                    </el-form-item>
                    <el-form-item style="text-align: center;">
                        <el-button type="primary" @click="submitForm('ruleForm')">提交认证</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import {Message} from 'element-ui';
export default{
    components:{
        PHeader,
        PFooter,
    },
    data(){
        return{
            ruleForm: {
                username:'',
                userhead: '',
                fieldvalue:[],
                tipsdata:[],
                usermark:''
            },
            fieldlist:[
                {id:1,text:'志愿填报',checked:false,value:'1111'},
                {id:2,text:'高招政策',checked:false,value:'2222'},
                {id:3,text:'心理咨询',checked:false,value:'3333'},
                {id:4,text:'艺考传媒',checked:false,value:'4444'},
                {id:5,text:'升学规划',checked:false,value:'5555'},
                {id:6,text:'留学咨询',checked:false,value:'6666'}
            ],
            tipstxt:'',
            rules: {
                username: [
                    { required: true, message: '请输入真实姓名', trigger: 'blur' }
                ],
                userhead: [
                    { required: true, message: '请上传头像', trigger: 'blur' }
                ],
                fieldvalue: [
                    { required: true, message: '请选择擅长领域', trigger: 'blur' }
                ],
                tipsdata:[
                    { required: true, message: '请选添加头衔', trigger: 'blur' }
                ],
                usermark:[
                    { required: true, message: '请输入简介', trigger: 'blur' }
                ],
            }
        }
    },
    methods:{
        submitForm(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    
                } else {
                    return false;
                }
            });
        },
        tipsadd(){
            if(this.tipstxt){
                if(this.ruleForm.tipsdata.indexOf(this.tipstxt)!=-1){
                    Message({type: 'error',message: '该头衔已存在'});
                }
                else{
                    this.ruleForm.tipsdata.push(this.tipstxt);
                    this.tipstxt='';
                }
            }
            else{
                Message({type: 'error',message: '请输入头衔'});
            }
        },
        tipsremove(item){
            this.ruleForm.tipsdata=this.ruleForm.tipsdata.filter(obj=>obj!==item);
        },
        changecheckbox(item){
            if(item.checked){
                this.ruleForm.fieldvalue.push(item);
            }
            else{
                this.ruleForm.fieldvalue=this.ruleForm.fieldvalue.filter(ch => ch.value !== item.value);
            }
        },
        handleAvatarSuccess(res, file) {
            this.ruleForm.userhead = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const types = ["image/jpeg", "image/png"];
            const isType = types.includes(file.type);
            const isLt2M = file.size / 1024 < 200;
            if (!isType) {
                Message.error('上传头像图片只能是 JPG 或 PNG 格式!');
            }
            if (!isLt2M) {
                Message.error('上传头像图片大小不能超过 200KB!');
            }
            return isType && isLt2M;
        }
    }
}
</script>
<style lang="less" scoped>
.settleinbox{
    max-width: 1420px;
    margin: 20px auto 50px;
    user-select: none;
    .settleincon{
        margin: 0px 20px;
        border: 1px solid #eee;
        box-shadow: 0px 0px 3px 1px #ebeaea;
        background-color: #fff;
        .title{
            text-align: center;
            font-size: 24px;
            letter-spacing: 5px;
            color: #666;
            font-weight: bold;
            margin: 30px auto 30px;
        }
        .tipsbox{
            width: 100%;
            min-height: 50px;
            border: 1px dashed #afafaf;
            border-radius: 4px;
            .tipshide{
                margin-top: 4px;
                i{
                    color: #a7a5a5;
                    font-size: 16px;
                    margin-left: 15px;
                    font-style: normal;
                }
                span{
                    padding: 2px 8px;
                    background-color: #f3f3f3;
                    margin-right: 5px;
                    border-radius: 10px;
                    color: #a7a5a5;
                    font-size: 14px;
                }
            }
            .tipsshow{
                margin-top: 4px;
                margin: 10px 10px 10px;
                line-height: 30px;
                span{
                    padding: 2px 10px;
                    background-color: #f9ede0;;
                    margin-right: 10px;
                    border-radius: 10px;
                    color: #6a6969;
                    font-size: 14px;
                    i{
                        color: #666;
                        font-size: 14px;
                        margin-left: 8px;
                        cursor: pointer;
                    }
                    i:hover{
                        color: rgb(var(--themecolor));
                    }
                }
            }
        }
        .tipsform{
            width: 100%;
            height: 50px;
            input{
                width: 50%;
                height: 38px;
                border: 1px solid #c9c8c8;
                border-radius: 4px;
                line-height: 1;
                line-height: 38px;
                margin-top: 5px;
                float: left;
                color: #666;
                text-indent: 15px;
            }
            input:focus,input:hover{
                border-color: rgba(var(--themecolor),0.6);
            }
            .btn{
                width: 80px;
                height: 38px;
                line-height: 38px;
                color: #666;
                font-size: 14px;
                background-color: #eee;
                border: 1px solid rgba(var(--themecolor),0.4);
                border-radius: 4px;
                float: left;
                text-align: center;
                letter-spacing: 2px;
                margin-top: 5px;
                margin-left: 10px;
            }
            .btn:hover{
                cursor: pointer;
                border: 1px solid rgba(var(--themecolor),0.7);
            }
        }
    }
}
</style>
<style lang="less">
.settleinbox .settleincon .el-form .el-form-item{
    max-width: 80%;
    min-width: 400px;
}
.settleinbox .settleincon .el-form .el-form-item .el-input__inner:hover,.settleinbox .settleincon .el-form .el-form-item .el-textarea__inner:hover{
    border-color: rgba(var(--themecolor),0.6);
}
.settleinbox .settleincon .el-form .el-form-item .el-input__inner:focus,.settleinbox .settleincon .el-form .el-form-item .el-textarea__inner:focus{
    border-color: rgba(var(--themecolor),0.6);
}
.settleinbox .settleincon .el-form .el-form-item .el-input__inner{
    border: 1px solid #c9c8c8;
    height: 50px;
}
.settleinbox .settleincon .el-form .el-form-item .el-textarea__inner{
    border: 1px solid #c9c8c8;
    resize: none;
}
.settleinbox .settleincon .el-form .el-form-item .el-button--primary{
    width: 200px;
    height: 50px;
    font-size: 16px;
    background-color: rgba(var(--themecolor),0.85);
    border-color: rgba(var(--themecolor),0.85);
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
}
.settleinbox .settleincon .el-form .el-form-item .el-button--primary:hover{
    background-color: rgba(var(--themecolor),1);
    border-color: rgba(var(--themecolor),1);
}
.settleinbox .settleincon .avatar-uploader{
    float: left;
}
.settleinbox .settleincon .avatar-uploader .el-upload {
    border: 1px dashed #a2a3a5;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100px;
    height: 100px;
    border-radius: 100px;
}
.settleinbox .settleincon .avatar-uploader .el-upload:hover {
    border-color: rgba(var(--themecolor),0.7);
}
.settleinbox .settleincon .avatar-uploader .el-upload .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    line-height: 100px;
    text-align: center;
    width: 100%;
    display: block;
}
.settleinbox .settleincon .avatar-uploader .el-upload .avatar {
    width: 100%;
    height: 100%;
    display: block;
    margin-left: 20px;
}
.settleinbox .settleincon .mark{
    font-size: 14px;
    color: #666;
    margin-left: 20px;
    margin-top: 30px;
    display: inline-block;
}
.settleinbox .settleincon .el-form-item__label{
    line-height: 50px;
}
.settleinbox .settleincon .head .el-form-item__label{
    line-height: 100px;
}
.settleinbox .settleincon .el-checkbox__input .el-checkbox__inner{
    border-color:#bdbcbc;
}
.settleinbox .settleincon .el-checkbox__input.is-checked .el-checkbox__inner{
    background-color: rgb(var(--themecolor));
    border-color: rgb(var(--themecolor));
}
.settleinbox .settleincon .el-checkbox__input.is-checked + .el-checkbox__label{
    color: rgb(var(--themecolor));
}
</style>